import React from "react";
import ContactForm from "../ContactFrom/ContactForm";
import icon1 from "../../images/icons/icon_map_mark_2.svg";
import icon2 from "../../images/icons/icon_calling_2.svg";
import icon3 from "../../images/icons/icon_mail_3.svg";
import icon4 from "../../images/icons/icon_calendar_2.svg";

const ContactSection = (props) => {
  return (
    <section className="contact_section section_space bg-light">
      <div className="container">
        <div className="contact_info_box row">
          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="iconbox_block text-center">
              <div className="iconbox_icon">
                <img src={icon1} alt="Map Mark SVG Icon" />
              </div>
              <div className="iconbox_content">
                <h3 className="iconbox_title">الموقع</h3>
                <p className="mb-0">منية النصر شارع مجمع المحاكم</p>
                <p className="mb-0">امام صيدلية ابراهيم عوضين</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="iconbox_block text-center">
              <div className="iconbox_icon">
                <img src={icon2} alt="Calling SVG Icon" />
              </div>
              <div className="iconbox_content">
                <h3 className="iconbox_title">للتواصل</h3>
                <p className="mb-0">010 00022957</p>
                {/* <div className="mb-0">+88(0) 555-01117</div> */}
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="iconbox_block text-center">
              <div className="iconbox_icon">
                <img src={icon3} alt="User Check SVG Icon" />
              </div>
              <div className="iconbox_content">
                <h3 className="iconbox_title">Email</h3>
                <p className="mb-0">elzaeem.print@gmail.com</p>
                <p className="mb-0">elzaeem.@elzaeemadv.com</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="iconbox_block text-center">
              <div className="iconbox_icon">
                <img src={icon4} alt="Calendar SVG Icon" />
              </div>
              <div className="iconbox_content">
                <h3 className="iconbox_title">تشرفنا بزيارتك</h3>
                <p className="mb-0">السبت -الخميس 11 ص-11م</p>
                <p className="mb-0">الجمعة مغلق</p>
              </div>
            </div>
          </div>
        </div>
        <div className="section_space pb-0">
          <div className="row justify-content-lg-between">
            <div className="col-lg-7">
              <div className="contact_form mb-0">
                <h3 className="details_item_info_title mb-1">
                  Send Us A Message
                </h3>
                <p className="mb-5">
                  Give us chance to serve and bring magic to your brand.
                </p>
                <ContactForm />
              </div>
            </div>
            <div className="col-lg-5">
              <div className="gmap_canvas ps-lg-5">
                <iframe
                  title="map"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d27321.325575590272!2d31.641123089212755!3d31.13291276970152!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14f9d72d3eff6237%3A0x931c9d2ad41d288a!2z2KfZhNiy2LnZitmFINmE2YTYr9i52KfZitipINmI2KfZhNil2LnZhNin2YY!5e0!3m2!1sen!2seg!4v1733043588703!5m2!1sen!2seg"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactSection;
