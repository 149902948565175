import React from "react";
import Bg from "../../images/backgrounds/bg_image_1.webp";
import { Link } from "react-router-dom";

const CtaSection = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <section
      className="calltoaction_section parallaxie"
      style={{ backgroundImage: `url(${Bg})` }}
    >
      <div className="container text-center">
        <div className="heading_block text-white">
          <h2 className="heading_text">أعلانك عندنا</h2>
          <p className="heading_description mb-0">
            حوّل أفكارك إلى واقع مبدع! دعنا نصنع معًا حملتك الإعلانية القادمة
          </p>
        </div>
        <Link onClick={ClickHandler} to="/contact" className="btn btn-primary">
          <span className="btn_label" data-text="Contact Us Today!">
            تواصل معنا الان
          </span>
          <span className="btn_icon">
            <i className="fa-solid fa-arrow-up-right"></i>
          </span>
        </Link>
      </div>
    </section>
  );
};

export default CtaSection;
