import pImg1 from "../images/portfolio/portfolio_item_image_1.webp";
import pImg2 from "../images/portfolio/portfolio_item_image_2.webp";
import pImg3 from "../images/portfolio/portfolio_item_image_3.webp";
import pImg4 from "../images/case/case_image_1.webp";
import pImg5 from "../images/case/case_image_2.webp";
import pImg6 from "../images/case/case_image_3.webp";

import pImg7 from "../images/portfolio/portfolio_item_image_4.webp";
import pImg8 from "../images/portfolio/portfolio_item_image_5.webp";
import pImg9 from "../images/portfolio/portfolio_item_image_6.webp";
import pImg10 from "../images/portfolio/portfolio_item_image_7.webp";
import pImg11 from "../images/portfolio/portfolio_item_image_8.webp";
import pImg12 from "../images/portfolio/portfolio_item_image_9.webp";
import pImg13 from "../images/portfolio/portfolio_item_image_10.webp";
import pImg14 from "../images/portfolio/portfolio_item_image_11.webp";

import tImg1 from "../images/case/icon_angular.svg";
import tImg2 from "../images/case/icon_elephent.svg";
import tImg3 from "../images/case/icon_netcore.svg";
import tImg4 from "../images/case/vuejs.svg";
import tImg5 from "../images/case/icon_python.svg";

const Project = [
  {
    Id: "1",
    pImg: pImg1,
    title: "المشروع الاول",
    slug: "المشروع الاول",
    sub: "App Design",
    description:
      "Our all-encompassing IELTS Coaching curriculum encompasses every aspect...",
  },
  {
    Id: "2",
    pImg: pImg2,
    title: "المشروع الثاني",
    slug: "TOEFL-Coaching",
    sub: "",
    description:
      "Our experienced coaches are not just educators; they are partners in your journey..",
  },
  {
    Id: "3",
    pImg: pImg3,
    title: "المشروع الثالث",
    slug: "Dashboard-Design",
    sub: "Web Design",
    description:
      "The contents of the citizenship test typically cover a range of subjects ...",
  },
  {
    Id: "4",
    pImg: pImg1,
    title: "المشروع الرابع",
    slug: "Mobile-App-Design",
    sub: "App Design",
    description:
      "Our all-encompassing IELTS Coaching curriculum encompasses every aspect...",
  },
  {
    Id: "5",
    pImg: pImg2,
    title: "المشروع الخامس",
    slug: "TOEFL-Coaching",
    sub: "",
    description:
      "Our experienced coaches are not just educators; they are partners in your journey..",
  },
  {
    Id: "6",
    pImg: pImg3,
    title: "المشروع السادس",
    slug: "Dashboard-Design",
    sub: "Web Design",
    description:
      "The contents of the citizenship test typically cover a range of subjects ...",
  },
  {
    Id: "7",
    pImg: pImg4,
    title: "المشروع السابع",
    slug: "Astarte-Medical",
    sub: "Computer Software",
    Industry: "Computer software",
    Country: "Germany, Issum",
    Technologies1: tImg1,
    Technologies2: tImg2,
    description:
      "Creating software for Astarte Medical involves a meticulous process aimed at addressing their specific needs and objectives. It begins with thorough research and planning to understand the requirements goals of the project.",
  },
  {
    Id: "8",
    pImg: pImg5,
    title: "المشروع الثامن",
    slug: "CAE-Blue-Phantom",
    sub: "Healthcare",
    Industry: "Wellness & Fitness",
    Country: "Canada, Alberta",
    Technologies1: tImg1,
    Technologies2: tImg3,
    description:
      "CAE Blue Phantom is a cutting-edge simulation technology designed to revolutionize medical training and education. Utilizing advanced virtual reality and artificial intelligence, CAE Blue Phantom provides.",
  },
  {
    Id: "9",
    pImg: pImg6,
    title: "المشروع التاسع",
    slug: "Liberkeys",
    sub: "Real Estate",
    Industry: "Real estate",
    Country: "France, Paris",
    Technologies1: tImg4,
    Technologies2: tImg5,
    description:
      "Liberkeys is a modern real estate platform that is revolutionizing the way people buy, sell, and rent properties. With a focus on transparency, efficiency, and customer satisfaction, Liberkeys streamlines.",
  },
  {
    Id: "10",
    pImg: pImg7,
    title:
      "Driving Digital Transformation Explore the Depth of Our IT Projects",
    slug: "Driving-Digital-Transformation-Explore-the-Depth-of-Our-IT Projects",
    thumb1: "Logo Design",
    thumb2: "Finance",
    category: "technology",
  },
  {
    Id: "17",
    pImg: pImg8,
    title: "Explore Our IT Solutions Portfolio for Public Sector Organizations",
    slug: "Explore-Our-IT-Solutions",
    thumb1: "App Design",
    thumb2: "Public",
    category: "helpdesk",
  },
  {
    Id: "18",
    pImg: pImg9,
    title: "Innovative Solutions Showcase the Diversity of Our IT Portfolio",
    slug: "Innovative-Solutions-Showcase-the-Diversity-of-Our-IT-Portfolio",
    thumb1: "Card Design",
    thumb2: "Transpiration",
    category: "analysis",
  },
  {
    Id: "19",
    pImg: pImg10,
    title: "Tech Triumphs Celebrating Our Achievements in IT Solutions",
    slug: "Tech-Triumphs-Celebrating-Our-Achievements-in-IT-Solutions",
    thumb1: "Web Design",
    thumb2: "Logistic",
    category: "technology",
  },
  {
    Id: "20",
    pImg: pImg11,
    title:
      "Revolutionizing IT Strategies A Closer Look at Our Dynamic IT Solutions",
    slug: "Revolutionizing-IT-Strategies-A-Closer-Look-at-Our-Dynamic-IT-Solutions",
    thumb1: "Web Design",
    thumb2: "Fution",
    category: "analysis",
  },
  {
    Id: "21",
    pImg: pImg12,
    title: "Cloud Migration and Integration Project IT Solutions Portfolio",
    slug: "Cloud-Migration-and-Integration-Project-IT-Solutions-Portfolio",
    thumb1: "Web Design",
    thumb2: "Energy",
    category: "helpdesk",
  },
  {
    Id: "22",
    pImg: pImg13,
    title: "Pioneering Progress Exploring the Evolution and Impact of",
    slug: "Pioneering-Progress-Exploring-the-Evolution-and-Impact-of",
    thumb1: "Web Design",
    thumb2: "Health",
    category: "marketing",
  },
  {
    Id: "23",
    pImg: pImg14,
    title: "Unlocking Potential Explore Our Comprehensive IT Portfolio",
    slug: "Unlocking-Potential-Explore-Our-Comprehensive-IT-Portfolio",
    thumb1: "Web Design",
    thumb2: "Industry",
    category: "technology",
  },
];

export default Project;
