import React, { useState } from "react";
import { Link } from "react-router-dom";
import about1 from "../../images/about/about_image_9.webp";
// import about2 from "../../images/about/about_image_11.webp";
// import about3 from "../../images/about/about_image_10.webp";
import ModalVideo from "react-modal-video";
import "../../../node_modules/react-modal-video/scss/modal-video.scss";

const About = (props) => {
  const [isOpen, setOpen] = useState(false);

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <section className="about_section section_space">
      <div className="container">
        <div className="row align-items-center justify-content-lg-between">
          <div className="">
            <div className="">
              <div className="about_content">
                <div className="heading_block">
                  <div className="heading_focus_text">
                    <span className="badge bg-secondary text-white">
                      الزعيم
                    </span>
                    شركة
                  </div>
                  <h2 className="heading_text"> رضا العميل غايتنا</h2>
                  <p className="heading_description mb-0">
                    حوّل أفكارك إلى واقع مبدع! دعنا نصنع معًا حملتك الإعلانية
                    القادمة شركة الزعيم للدعاية والعلإن معنا، إعلانك ليس مجرد
                    إعلان – بل تجربة لا تُنسى تميز في السوق مع حلول إعلانية
                    مبتكرة. تواصل معنا اليوم
                  </p>
                </div>
                <h2 className="heading_text"> أهم أعمالنا</h2>
              </div>
            </div>
            <div className="about_image_1">
              {/* <img src={about1} alt="Elzaeem - About" /> */}
              <div className="col-lg-10">
                {/* ابو عادل */}
                <div className="gmap_canvas ps-lg-5">
                  <iframe
                    src="https://www.behance.net/embed/project/209877751?ilo0=1"
                    height="316"
                    width="404"
                    allowfullscreen
                    lazyload
                    frameborder="0"
                    allow="clipboard-write"
                    refererPolicy="strict-origin-when-cross-origin"
                  ></iframe>
                </div>
              </div>
              <div className="col-lg-10">
                {/* اتليه فيروز */}
                <div className="gmap_canvas ps-lg-5">
                  <iframe
                    src="https://www.behance.net/embed/project/209879473?ilo0=1"
                    height="316"
                    width="404"
                    allowfullscreen
                    lazyload
                    frameborder="0"
                    allow="clipboard-write"
                    refererPolicy="strict-origin-when-cross-origin"
                  ></iframe>
                </div>
              </div>
              <div className="col-lg-10">
                <div className="gmap_canvas ps-lg-5">
                  <iframe
                    src="https://www.behance.net/embed/project/209878829?ilo0=1"
                    height="316"
                    width="404"
                    allowfullscreen
                    lazyload
                    frameborder="0"
                    allow="clipboard-write"
                    refererPolicy="strict-origin-when-cross-origin"
                  ></iframe>
                </div>
              </div>
              <div className="col-lg-10">
                <div className="gmap_canvas ps-lg-5">
                  <iframe
                    src="https://www.behance.net/embed/project/209879107?ilo0=1"
                    height="316"
                    width="404"
                    allowfullscreen
                    lazyload
                    frameborder="0"
                    allow="clipboard-write"
                    refererPolicy="strict-origin-when-cross-origin"
                  ></iframe>
                </div>
              </div>
              <div className="col-lg-10">
                <div className="gmap_canvas ps-lg-5">
                  <iframe
                    src="https://www.behance.net/embed/project/209878239?ilo0=1"
                    height="316"
                    width="404"
                    allowfullscreen
                    lazyload
                    frameborder="0"
                    allow="clipboard-write"
                    refererPolicy="strict-origin-when-cross-origin"
                  ></iframe>
                </div>
              </div>
              <div className="col-lg-10">
                <div className="gmap_canvas ps-lg-5">
                  <iframe
                    src="https://www.behance.net/embed/project/209878091?ilo0=1"
                    height="316"
                    width="404"
                    allowfullscreen
                    lazyload
                    frameborder="0"
                    allow="clipboard-write"
                    refererPolicy="strict-origin-when-cross-origin"
                  ></iframe>
                </div>
              </div>
              <div className="col-lg-10">
                <div className="gmap_canvas ps-lg-5">
                  <iframe
                    src="https://www.behance.net/embed/project/209877029?ilo0=1"
                    height="316"
                    width="404"
                    allowfullscreen
                    lazyload
                    frameborder="0"
                    allow="clipboard-write"
                    refererPolicy="strict-origin-when-cross-origin"
                  ></iframe>
                </div>
              </div>

              <div className="col-lg-10">
                <div className="gmap_canvas ps-lg-5">
                  <iframe
                    src="https://www.behance.net/embed/project/209879473?ilo0=1"
                    height="316"
                    width="404"
                    allowfullscreen
                    lazyload
                    frameborder="0"
                    allow="clipboard-write"
                    refererPolicy="strict-origin-when-cross-origin"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <ModalVideo
        channel="youtube"
        autoplay
        isOpen={isOpen}
        videoId="7e90gBu4pas"
        onClose={() => setOpen(false)}
      /> */}
    </section>
  );
};

export default About;
